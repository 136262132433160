import { ref, watch } from '@vue/composition-api'
import moment from 'moment'
import axios from '@axios'

export default function useReport() {
	const notify = ref({})

	const filter = ref({
		date1: moment().startOf('month').format('YYYY-MM-DD'),
		date2: moment().format('YYYY-MM-DD'),
	})

	const loading = ref(false)

	const reportData = ref(null)

	let lastQuery = ''
	const fetchDatas = (force = false) => {
		const queryParams = {}

		for (let key in filter.value) {
			let value = filter.value[key]
			if (value !== null && value !== '') {
				queryParams[key] = value
			}
		}

		const newQuery = JSON.stringify(queryParams)

		if (force || lastQuery !== newQuery) {
			lastQuery = newQuery

			axios.get('/api/report/cash', { params: queryParams })
				.then(({ data }) => {

					if (data.code === 200) {
						reportData.value = data.data
						console.log({ data})
					}

					loading.value = false
				})
				.catch(error => {
					console.log(error)
					loading.value = false
					notify.value = { type: 'error', text: error, time: Date.now() }
				})
		}

		lastQuery = JSON.stringify(queryParams)
	}

	watch(
		filter,
		() => {
			fetchDatas()
		},
		{ deep: true },
	)

	return {
		filter,
		fetchDatas,

		reportData,
		loading,
		notify,
	}
}
