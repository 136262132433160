<template>
	<v-card id='data-list'>
		<!-- search -->
		<v-card-text class='d-flex '>

			<report-filter v-model='filter' />

		</v-card-text>


		<v-simple-table style='max-width: 500px;' v-if='reportData'>
			<tbody>
			<tr>
				<td>{{ filter.date1 |date }}</td>
				<td style='white-space: nowrap; text-align: right;'>{{ reportData.balance1 | summa }}</td>
			</tr>
			<tr>
				<td>Kirim</td>
				<td style='white-space: nowrap; text-align: right;'>{{ reportData.income | summa }}</td>
			</tr>
			<tr>
				<td>Xarajat</td>
				<td style='white-space: nowrap; text-align: right;'>{{ reportData.expense | summa }}</td>
			</tr>
			<tr>
				<td>{{ filter.date2 |date }}</td>
				<td style='white-space: nowrap; text-align: right;'>{{ reportData.balance2 | summa }}</td>
			</tr>
			</tbody>
		</v-simple-table>

		<br>
		<br>
		<br>

		<v-row>
			<v-col cols='5'>
				<v-simple-table style='' v-if='reportData && reportData.expenseByCategory'>
					<thead>
					<tr>
						<th colspan='2'>Xarajat turlari bo'yicha</th>
					</tr>
					</thead>

					<tbody>
					<tr v-for='(item, index) in reportData.expenseByCategory' :key='index'>
						<td>{{ item.category }}</td>
						<td style='white-space: nowrap; text-align: right;'>{{ item.amount | summa }}</td>
					</tr>
					</tbody>
				</v-simple-table>
			</v-col>

			<v-col cols='5'>
				<v-simple-table style='' v-if='reportData && reportData.incomeBySubject'>
					<thead>
					<tr>
						<th colspan='2'>Tushum kurs bo'yicha</th>
					</tr>
					</thead>

					<tbody>
					<tr v-for='(item, index) in reportData.incomeBySubject' :key='index'>
						<td>{{ item.subject }}</td>
						<td style='white-space: nowrap; text-align: right;'>{{ item.amount | summa }}</td>
					</tr>
					</tbody>
				</v-simple-table>
			</v-col>
		</v-row>


	</v-card>
</template>

<script>

import ReportFilter from './ReportFilter.vue'
import useReport from './useReport'

export default {
	name: 'Report',
	components: {
		ReportFilter,
	},
	setup() {

		//logics
		const {
			filter,
			reportData,
		} = useReport()

		return {
			filter,
			reportData,
		}
	},
}
</script>

<style scoped>

</style>