import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VMenu,{ref:"menuref",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"SANA ...dan","persistent-hint":"","prepend-icon":_vm.icons.mdiCalendar,"outlined":"","dense":"","clearable":""},model:{value:(_vm.filter.date1),callback:function ($$v) {_vm.$set(_vm.filter, "date1", $$v)},expression:"filter.date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.filter.date1),callback:function ($$v) {_vm.$set(_vm.filter, "date1", $$v)},expression:"filter.date1"}})],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"SANA ...gacha","persistent-hint":"","prepend-icon":_vm.icons.mdiCalendar,"readonly":"","outlined":"","dense":"","clearable":""},model:{value:(_vm.filter.date2),callback:function ($$v) {_vm.$set(_vm.filter, "date2", $$v)},expression:"filter.date2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"primary"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.filter.date2),callback:function ($$v) {_vm.$set(_vm.filter, "date2", $$v)},expression:"filter.date2"}})],1)],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VSelect,{staticClass:"data-list-search me-3",attrs:{"items":_vm.cashboxes,"item-text":"name","item-value":"id","dense":"","outlined":"","hide-details":"","label":"KASSA","clearable":""},model:{value:(_vm.filter.cashbox_id),callback:function ($$v) {_vm.$set(_vm.filter, "cashbox_id", $$v)},expression:"filter.cashbox_id"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }