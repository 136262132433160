<template>
	<div>
		<v-row>

			<v-col cols='4'>
				<v-menu
					ref='menuref'
					v-model='menu1'
					:close-on-content-click='false'
					transition='scale-transition'
					offset-y
					max-width='290px'
					min-width='auto'
				>
					<template v-slot:activator='{ on, attrs }'>
						<v-text-field
							v-model='filter.date1'
							label='SANA ...dan'
							persistent-hint
							:prepend-icon='icons.mdiCalendar'
							v-bind='attrs'
							v-on='on'
							outlined
							dense
							clearable
						></v-text-field>
					</template>
					<v-date-picker
						v-model='filter.date1'
						no-title
						color='primary'
						@input='menu1 = false'
					></v-date-picker>

				</v-menu>
			</v-col>

			<v-col cols='4'>
				<v-menu
					v-model='menu2'
					:close-on-content-click='false'
					transition='scale-transition'
					offset-y
					max-width='290px'
					min-width='auto'
				>
					<template v-slot:activator='{ on, attrs }'>
						<v-text-field
							v-model='filter.date2'
							label='SANA ...gacha'
							persistent-hint
							:prepend-icon='icons.mdiCalendar'
							readonly
							v-bind='attrs'
							v-on='on'
							outlined
							dense
							clearable
						></v-text-field>
					</template>
					<v-date-picker
						v-model='filter.date2'
						no-title
						color='primary'
						@input='menu2 = false'
					></v-date-picker>
				</v-menu>
			</v-col>

			<v-col cols='4'>
				<v-select
					v-model='filter.cashbox_id'
					:items='cashboxes'
					item-text='name'
					item-value='id'
					dense
					outlined
					hide-details
					label='KASSA'
					class='data-list-search me-3'
					clearable
				></v-select>
			</v-col>

		</v-row>

	</div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'
import { mdiCalendar, mdiFilterOutline } from '@mdi/js'

export default {
	name: 'ReportFilter',
	props: ['value'],
	setup(props, { emit }) {

		const branch_id = computed(() => store.state.branch_id)
		watch(branch_id, (value) => {
			filter.value.place_id = value
		})

		const filter = ref(props.value)

		//default fields
		filter.value = Object.assign({
			cashbox_id: '',
			expense_category_id: '',
			date1: '',
			date2: '',
			place_id: branch_id.value ?? '',
		}, props.value)

		//return with default fields
		emit('input', filter.value)

		watch(filter, (value) => {
			emit('input', value)
		}, { deep: true })

		const places = ref([])
		const loadPlaces = () => {
			axios.get('/api/places').then(response => {
				places.value = response.data.data
			})
		}
		loadPlaces()

		const cashboxes = ref([])
		const loadCashboxes = () => {
			axios.get('/api/cashboxes').then(response => {
				cashboxes.value = response.data.data
			})
		}
		loadCashboxes()

		const expenseCategories = ref([])
		const loadExpenseCategories = () => {
			axios.get('/api/expense-categories').then(response => {
				expenseCategories.value = response.data.data
			})
		}
		loadExpenseCategories()

		// Filter date picker
		const date = ref(new Date().toISOString().substr(0, 10))
		const menu1 = ref(false)
		const menu2 = ref(false)


		// Datepicker
		// const picker = new Date().toISOString().substr(0, 10)
		const isDate = ref(false)
		const isDateTwo = ref(false)


		const formatDate = dates => {
			if (!dates) return null
			const [year, month, day] = dates.split('-')

			return `${month}/${day}/${year}`
		}

		let dateFormatted = formatDate(new Date().toISOString().substr(0, 10))

		const parseDate = dates => {
			if (!dates) return null
			const [month, day, year] = dates.split('/')

			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		}

		const computedDateFormatted = computed(() => formatDate(date.value))

		watch(date, () => {
			dateFormatted = formatDate(date.value)
		})

		return {
			filter,

			isDate,
			isDateTwo,
			date,
			menu1,
			menu2,
			// picker,


			dateFormatted,
			computedDateFormatted,
			parseDate,
			formatDate,

			expenseCategories,
			cashboxes,
			places,

			place_id: branch_id,

			icons: {
				mdiFilterOutline,
				mdiCalendar,
			},
		}
	},
}
</script>

<style scoped>

</style>